<template lang="pug">
  b-container#DeliveryDetailView(fluid="lg")
      div.d-flex.align-items-center(@click="$router.go(-1)")
          span.material-icons.icon(class="icon-atras" ) forward
          p.title-detail-delivery Detalle envío
      b-card(
          bg-variant="light"
          class="shadow card-envios position-relative"
      )
          b-badge(
          :style="getBadgeStyle(delivery.type)"
          v-show="!delivery.entregado"
          class="custom-badge"
          ) {{ delivery.type }}
          p.text-title {{ `#${delivery.numberStore} ${delivery.nameStore}` }}
          b-row.row-between
            p.destinatario-text {{ delivery.finalCustomer }}
            b-badge.tags(pill) {{ delivery.tagsName }}
          p.text-address {{ delivery.address }}
          p.text-commune {{ delivery.commune }}
          p.text-observation.small-text Observaciones: {{ delivery.observations ? delivery.observations : '-' }}
          p.text-tracking.small-text N° Seguimiento: {{ delivery.drivInCode }}
          hr(class="hr-custom mt-1")
          b-card-text.card-text-cellphone(v-if="delivery.contactPhone && !delivery.entregado")
            div(@click="makeCall(delivery.contactPhone)" style="cursor: pointer; display: flex; align-items: center;")
              span.material-icons.icon(class="icon-cellphone") call
              span.text-phone {{ delivery.contactPhone }}
            div.ml-auto(class="d-flex" style="gap: 14px; align-items: center;")
              span.material-icons.icon(style="font-size: 26px;" @click="copyToClipboard(delivery.contactPhone)") content_copy
              font-awesome-icon(:icon="['fab', 'whatsapp']" class="icon-whatsapp" size="2x" @click="openWhatsApp")
          //- Apartado SOLO para cuando esté ENTREGADO el envio, "APPROVED, REJECTED o PARTIAL"
          template(v-if="delivery.entregado")
            hr.hr-custom
            p.text-homologate.small-text {{ delivery.receiverRut }}
            p.text-homologate.small-text Comentario:
            p.text-homologate.small-text {{ delivery.comment }}
            hr.hr-custom(class="mt-1")
            div.scroll-container(v-if="delivery.images.length > 0")
              b-img(v-for="(image, index) in delivery.images"
                    :key="index"
                    :src="image"
                    class="img-details")
          b-row(v-if="!delivery.entregado")
              b-col
                  b-button(size="sm" class="button-general button-maps w-100 d-flex align-items-center justify-content-center" @click="openMaps('google')")
                    font-awesome-icon(:icon="['fas', 'map-marker-alt']" size="lg" class="mr-2")
                    span Navegar con Maps
              b-col
                  b-button(size="sm" class="button-general button-waze w-100  d-flex align-items-center justify-content-center"  @click="openMaps('waze')")
                    font-awesome-icon(:icon="['fab', 'waze']" size="lg"  class="icon-waze")
                    span Navegar con Waze
          b-button.mt-4(v-if="!delivery.entregado" class="button-complete text-white w-100 text-center" @click="handleComplete(delivery)") Completar Entrega
          b-button.mt-4(v-if="delivery.entregado" class="button-complete text-white w-100 text-center" @click="$router.push('/')") Volver al Inicio
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryDetailView',
  computed: {
    ...mapGetters(['deliverySelected']),
    delivery () {
      return this.deliverySelected
    }
  },
  methods: {
    getBadgeStyle (type) {
      return {
        backgroundColor: type === 'Retiro' ? '#A5DC93' : '#4D9A97',
        color: type === 'Retiro' ? '#4F4F4F' : 'white'
      }
    },
    openMaps (app) {
      const address = encodeURIComponent(`${this.delivery.address}, ${this.delivery.commune}`)
      let url
      if (app === 'google') {
        url = `https://www.google.com/maps/search/?api=1&query=${address}`
      } else if (app === 'waze') {
        url = `https://waze.com/ul?q=${address}`
      }
      window.open(url, '_blank')
    },
    openWhatsApp () {
      let phoneNumber = this.delivery.contactPhone.replace(/\s+/g, '')
      if (phoneNumber.startsWith('0')) {
        phoneNumber = phoneNumber.substring(1)
      }
      const fullNumber = `56${phoneNumber}`
      const url = `https://wa.me/${fullNumber}`
      window.open(url, '_blank')
    },
    handleComplete (delivery) {
      // console.log(delivery, 'envío a completar')
      localStorage.setItem('to_complete', JSON.stringify(delivery.id))
      this.$router.push('/completar-envio')
    },
    makeCall (phone) {
      window.open(`tel:${phone}`)
    },
    // Funcionalidad para copiar hacia el portapapeles el telefono
    async copyToClipboard (phone) {
      this.$bvToast.toast('Teléfono copiado al portapapeles', {
        title: 'Éxito',
        variant: 'success',
        solid: true
      })
      await navigator.clipboard.writeText(phone)
    }
  }
}
</script>

<style lang="scss" scoped>
.title-detail-delivery{
  color: #4F4F4F;
  font-size: 21px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  margin-top: 19px;
}
.icon-atras{
  transform: rotate(180deg);
  color:#264848;
  width: 24px;
  height: 24px;
  margin-right: 5px;

}
.card-text-cellphone {
  display: flex;
  align-items: center;
}

.text-phone {
  margin-right: 8px;
  color: #F7B232;
}

.icon-whatsapp {
  margin-left: auto;
  color: #4D9A97;
}
.hr-custom {
  margin-bottom: 8px;
  border: 1px solid #F8F7F7
}
.icon-cellphone {
  color: #F7B232;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.icon-waze {
  margin-right: 0.3rem;
}
.img-details{
  width: 77px;
  height: 110px;
  border-radius: 5px;
}
.scroll-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 10px;
}
.tags {
  background-color: #F7B232;
  margin-block: auto;
  margin-top: 10px;
  font-size: 15px;
  padding: 9px;
  font-weight: 600;
  width: 100px;
}
.row-between {
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}
</style>
