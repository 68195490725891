<template lang="pug">
div
  b-form(v-if="user.role==='admin'" @submit.stop.prevent="onSubmit")
    .box-form
      .material-icons.icon-input.pr-1 perm_identity
      b-form-group(
        label-for="customer-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Destinatario
        b-form-input#customer-input.input(
          v-model="form.finalCustomer"
          maxlength="150"
          :state="$v.$dirty ? !$v.form.finalCustomer.$invalid : null"
        )
    hr.linea
    .box-form
      .material-icons.icon-input.pr-1 phone
      b-form-group(
        label-for="customer-phone-input"
      )
        .input-title Teléfono
        b-form-input#customer-phone-input.input(
          v-model="form.contactPhone"
        )
    hr.linea
    .box-form(v-if="user.role!=='store' && !driverId")
      .material-icons.icon-input.pr-1 perm_identity
      b-form-group(
        label-for="driver-input"
      )
        .input-title Conductor
        b-form-select#driver-input.input(
          v-model="form.driverId"
          :options="driverOptions"
        )
    hr.linea()
    .box-form
      .material-icons.icon-input.pr-1 perm_identity
      b-form-group(
        label-for="supplier-input"
      )
        .input-title Proveedor
        b-form-input#supplier-input.input(
          v-model="form.supplierName"
          maxlength="50"
        )
    hr.linea()
    .box-form()
      .material-icons.icon-input.pr-1 store
      b-form-group(
        label-for="store-input"
      )
        .input-title Tienda
        v-select(
          v-model="storeSelected"
          :options="storeOptions"
          label="text"
          placeholder="Selecciona una Tienda"
          @option:selected="onChangeStore"
        )
          span(slot="no-options") Lo siento, no se pudo encontrar la tienda
    .box-form(v-if="!date && user.role!=='store'")
      .material-icons.icon-input.pr-1 calendar_today
      b-form-group(
        label-for="date-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Fecha
        b-form-input#date-input.input(
          v-model="form.date"
          type="date"
          readonly
          :state="$v.$dirty ? !$v.form.date.$invalid : null"
        )
    hr.linea(v-if="user.role!=='store' && !date")
    .box-form
      .material-icons.icon-input.pr-1 place
      b-form-group(
        label-for="commune-input"
      )
        .input-title Comuna
        b-form-input#commune-input.input(
          v-model="form.commune"
          maxlength="256"
        )
    hr.linea
    .box-form
      .material-icons.icon-input.pr-1 place
      b-form-group(
        label-for="street-input"
      )
        .input-title Dirección
        b-form-input#street-input.input(
          v-model="form.address"
          maxlength="256"
        )
    hr.linea
    .text-right
    div(v-if="editMode")
      .box-form
        .material-icons.icon-input.pr-1 place
        b-form-group(
          label-for="state-input"
        )
          .input-title Estado
          b-form-select#state-input.input(v-model="form.status")
            b-form-select-option(:value="null") Por entregar
            b-form-select-option(:value="'approved'") Aprobado
            b-form-select-option(:value="'assigned'") Asignado
            b-form-select-option(:value="'in-transit'") En Tránsito
            b-form-select-option(:value="'partial'") Parcial
            b-form-select-option(:value="'rejected'") Rechazado
      hr.linea
      .box-form
        .material-icons.icon-input.pr-1 pin
        b-form-group(
          label-for="code-input"
        )
          .input-title Código
          b-form-input#code-input.input(
            v-model="form.drivInCode"
            readonly
          )
      hr.linea()
      .box-form
        .material-icons.icon-input.pr-1 playlist_add_check
        b-form-group(
          label-for="observations-input"
        )
          .input-title Observaciones
          b-form-textarea#observations-input.input(
            v-model="form.observations"
            maxlength="256"
            no-resize
          )
      hr.linea()
      .box-form
        .material-icons.icon-input.pr-1 sell
        b-form-group(
          label-for="tags-input"
        )
          .input-title Tags
          b-form-select#tags.input(
              v-model="form.tags"
              :options="tagsOptions"
              :disabled="loading"
            )
      hr.linea()
      .box-form
        .material-icons.icon-input.pr-1 comment
        b-form-group(
          label-for="comentary-input"
        )
          .input-title Comentario de entrega
          b-form-textarea#commentary-input.input(
            v-model="form.comment"
            maxlength="256"
            no-resize
            disabled
          )
      hr.linea
    .text-right
      b-button(type="submit" :disabled="loading") Guardar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'
import { required } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'

export default {
  mixins: [MakeToastMixin],
  props: {
    delivery: {
      type: Object,
      default: null
    },
    editMode: Boolean,
    driverId: Number,
    noIncudeHide: Boolean,
    date: [Date, String]
  },
  components: {
    vSelect
  },
  data () {
    return {
      loading: false,
      isException: false,
      noExceptionAllowed: false,
      stores: [],
      driversToEmit: [],
      storesToEmit: [],
      storeSelected: null,
      form: {
        finalCustomer: this.editMode ? this.delivery.finalCustomer : '',
        contactPhone: this.editMode ? this.delivery.contactPhone : '',
        driverId: this.driverId ? this.driverId : (this.editMode ? this.delivery.driverId : null),
        supplierName: this.editMode ? this.delivery.supplierName : '',
        storeId: this.editMode ? this.delivery.storeId : null,
        customerName: this.editMode ? this.delivery.customerName : '',
        date: this.date ? this.date : (this.editMode ? this.$moment(this.delivery.date.substring(0, 10)).format('YYYY-MM-DD') : ''),
        status: this.editMode ? this.delivery.status : null,
        comment: this.editMode ? this.delivery.comment : '',
        observations: this.editMode ? this.delivery.observations : '',
        address: this.editMode ? this.delivery.address : '',
        commune: this.editMode ? this.delivery.commune : '',
        drivInCode: this.delivery.drivInCode,
        tags: this.delivery.tags
      },
      tagsOptions: [
        { text: 'N/A', value: null },
        { text: 'Flex', value: 'flex' },
        { text: 'Cambio', value: 'exchange' },
        { text: 'Devolución', value: 'return' }
      ]
    }
  },
  validations () {
    const form = {
      finalCustomer: { required }
    }
    return { form }
  },
  methods: {
    ...mapActions(['createDelivery', 'updateDelivery', 'listUser', 'listAllStoreName']),
    async onSubmit () {
      this.loading = true
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        if (this.editMode) {
          await this.onUpdateDelivery()
        } else {
          await this.onCreateDelivery()
        }
      }
      this.loading = false
    },
    async onCreateDelivery () {
      const response = await this.createDelivery({ data: this.form })
      if (response.status === 201) {
        this.makeSuccessToast('El viaje se creó correctamente')
        this.$emit('done')
        if (this.$route.meta.back) {
          this.$router.push(this.$route.meta.back)
        }
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al crear el viaje')
      }
    },
    async onUpdateDelivery () {
      if (this.delivery.customerName) this.storesToEmit.push(this.delivery.customerName)
      if (this.delivery.customerName !== this.form.customerName) this.storesToEmit.push(this.form.customerName)
      if (this.delivery.driverId) this.driversToEmit.push(this.delivery.driverId)
      if (this.delivery.driverId !== this.form.driverId) this.driversToEmit.push(this.form.driverId)
      const data = { ...this.form }
      delete data.date
      delete data.drivInCode
      const response = await this.updateDelivery({ id: this.delivery.id, data })
      if (response.status === 200) {
        this.makeSuccessToast('El viaje se editó correctamente')
        this.$emit('done', { drivers: this.driversToEmit, stores: this.storesToEmit })
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al editar el viaje')
      }
    },
    onChangeStore (store) {
      this.form.storeId = store.value
      this.form.customerName = store.text
    }
  },
  computed: {
    ...mapGetters(['users', 'user']),
    storeOptions () {
      const stores = []
      this.stores.forEach(store => {
        stores.push({ value: store.id, text: store.name })
      })
      return stores
    },
    driverOptions () {
      const users = []
      const drivers = this.users.filter(user => user.role === 'driver')
      drivers.forEach(user => {
        users.push({ value: user.id, text: user.name + ' ' + user.lastName })
      })
      return users
    }
  },
  async created () {
    if (this.user.role === 'admin') {
      if (!this.driverId) {
        this.listUser()
      }
      const stores = await this.listAllStoreName()
      this.stores = stores
      if (this.delivery?.storeId) {
        const storeFounded = this.stores.filter((s) => s.id === this.delivery.storeId)
        this.storeSelected = storeFounded.length ? storeFounded[0].name : null
        console.log(storeFounded, 'store', this.storeSelected)
      }
    }
  }
}
</script>
